import React, {useState} from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"
import Layout from "../components/layout"
import SEO from "../components/seo"
import covidCover from "../images/covid-cover.png"
import { sendEmail } from "../components/userFunctions"

const countries = [
"Argentina",
  "Bolivia",
  "Brasil",
  "Chile",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Ecuador",
  "El Salvador",
  "Guatemala",
  "Haití",
  "Honduras",
  "México",
  "Nicaragua Nicaragua",
  "Panamá",
  "Paraguay",
  "Perú",
  "República Dominicana",
  "Uruguay Uruguay",
  "Venezuela"
]

const project_stages = [
  "Venta",
  "Preventa",
  "Construccion",
  "Ultimas unidades"
]

const projects_qtys = [1,2,3,4,5,6,7,8,9,10]

const Covid = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [projects, setProjects] = useState("")
  const [projectStatus, setProjectStatus] = useState("")
  
  //ERRORS
  const [emailError, setEmailError] = useState("")
  const [nameError, setNameError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [countryError, setCountryError] = useState("")
  const [cityError, setCityError] = useState("")
  const [projectsError, setProjectsError] = useState("")
  const [projectStatusError, setProjectStatusError] = useState("")

  const validateFields = () => {
    let emailError = ""
    let nameError = ""
    let phoneError = ""
    let countryError = ""
    let cityError = ""
    let projectsError = ""
    let projectStatusError = ""

    if(email == ""){
      emailError = "Correo no puede ser vacio."
    }
    if(name == ""){
      nameError = "Nombre no puede ser vacio."
    }
    if(phone == ""){
      phoneError = "Telefono no puede ser vacio."
    }
    if(country == ""){
      countryError = "Selecciona un pais."
    }
    if(city == ""){
      cityError = "Ciudad no puede ser vacia."
    }
    if(projects == ""){
      projectsError = "Selecciona una cantidad."
    }
    if(projectStatus == ""){
      projectStatusError = "Selecciona una etapa."
    }

    if(emailError || nameError || phoneError || countryError || cityError || projectsError || projectStatusError){
      setEmailError(emailError)
      setNameError(nameError)
      setPhoneError(phoneError)
      setCountryError(countryError)
      setCityError(cityError)
      setProjectsError(projectsError)
      setProjectStatusError(projectStatusError)
      return false
    }else{
      setEmailError(emailError)
      setNameError(nameError)
      setPhoneError(phoneError)
      setCountryError(countryError)
      setCityError(cityError)
      setProjectsError(projectsError)
      setProjectStatusError(projectStatusError)
      return true
    }
  }


  
  const handleSuscribe = () => {
    const data = {
      email : email,
      name : name,
      phone : phone,
      country : country,
      city: city,
      projects : projects,
      projectStatus : projectStatus
    }
    const isValid = validateFields()
    if(isValid){
      sendEmail(data).then(res=>{
        console.log(res)
        window.location.replace('/gracias/')
      })
    }
  }

  
  return (
  <Layout>
    <SEO title="COVID-19" />
    <div className="hero is-medium">
      <div className="hero-body mainHeroCover">
        <div className="container has-text-centered">
          <div className="columns ">
            <div className="covid-header">
              <Fade delay={200}>
                <p className="land-about__subtitleAlt covid-pill">COVID-19</p>
                <h1 className="title land-about__title covid-title">
                  Mantén tus ventas y a tu equipo funcionando desde cualquier
                  lugar.
                </h1>
                <p className="covid-about">
                  Como respuesta al COVID-19, Bit se suma al apoyo de las
                  empresas de la industria inmobiliaria ofreciendo nuestras
                  herramientas de administración de ventas gratuitas durante
                  esta crisis, comenzando a pagar en agosto.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="hero is-medium">
      <div className="hero-about hero-cover">
        <div className="container has-text-centered">
          <div className="columns ">
            <Fade bottom delay={300}>
              <div>
                <img src={covidCover} />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
    <div className="hero is-medium">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="columns ">
            <div className="covid-header">
              <Fade delay={200}>
                <h1 className="title land-about__title">
                  Trabaja a distancia y administra tu equipo, prospectos, ventas
                  y cobranza desde un mismo lugar.
                </h1>
                <p className="land-about__subtitleAlt covid-titleMin">
                  Bit es la herramienta para hacerlo más fácil y seguro.
                </p>
              </Fade>
              <Fade bottom delay={300}>
                <div className="columns covid-list">
                  <div className="column">
                    <p className="covid-listItem">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="covid-listIcon"
                      >
                        <path
                          d="M18.3332 5.5L8.24984 15.5833L3.6665 11"
                          stroke="#244CD8"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Integra todos tus prospectos
                    </p>
                  </div>
                  <div className="column">
                    <p className="covid-listItem">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="covid-listIcon"
                      >
                        <path
                          d="M18.3332 5.5L8.24984 15.5833L3.6665 11"
                          stroke="#244CD8"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      Monitorea a tu equipo
                    </p>
                  </div>
                </div>
              </Fade>
              <Fade bottom delay={300}>
                <div className="columns covid-list">
                  <div className="column">
                    <p className="covid-listItem">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="covid-listIcon"
                      >
                        <path
                          d="M18.3332 5.5L8.24984 15.5833L3.6665 11"
                          stroke="#244CD8"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      Cotiza, aparta, vende y cobra
                    </p>
                  </div>
                  <div className="column">
                    <p className="covid-listItem">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="covid-listIcon"
                      >
                        <path
                          d="M18.3332 5.5L8.24984 15.5833L3.6665 11"
                          stroke="#244CD8"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      Mantén la info. actualizada
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="hero is-medium">
      <div className="hero-body covid-form">
        <div className="container">
          <div className="columns">
            <div className="column is-4 is-offset-1">
              <div className="covid-formAbout">
                <Fade left delay={200}>
                  <h1 className="land-intro__title intro-reveal">
                    Comienza a usar Bit hoy gratis
                  </h1>
                </Fade>
              </div>
            </div>
            <div className="column is-5 has-text-centered">
              <div>
                <Fade right delay={200}>
                  <div className="columns">
                    <div className="column covid-input">
                      <label>Nombre completo</label>
                      <input
                        type="text"
                        placeholder="Luis Suarez"
                        className="input"
                        onChange={e => setName(e.target.value)}
                      />
                      <label>{nameError}</label>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column covid-input">
                      <label>Correo electrónico</label>
                      <input
                        type="email"
                        className="input"
                        placeholder="lsuarez@mail.com"
                        onChange={e => setEmail(e.target.value)}
                      />
                      <label>{emailError}</label>
                    </div>
                    <div className="column covid-input">
                      <label>Teléfono</label>
                      <input
                        type="tel"
                        className="input"
                        placeholder="+52 81 1234 1234"
                        onChange={e => setPhone(e.target.value)}
                      />
                      <label>{phoneError}</label>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column covid-input">
                      <label>País</label>
                      <select className="input select" 
                        onChange={e => setCountry(e.target.value)}
                      >
                        <option>Selecciona tu país</option>
                        {countries.map((country,index) =>(
                          <option key={index} value={country}>{country}</option>
                        ))}
                      </select>
                      <label>{countryError}</label>
                    </div>
                    <div className="column covid-input">
                      <label>Ciudad</label>
                      <input
                        type="text"
                        className="input"
                        placeholder="Monterrey"
                        onChange={e => setCity(e.target.value)}
                      />
                      <label>{cityError}</label>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column covid-input">
                      <label>Cantidad de proyectos</label>
                      <select className="input select"
                      onChange={e => setProjects(e.target.value)}
                      >
                        <option>Selecciona tu cantidad</option>
                        {projects_qtys.map((projects_qty,index) =>(
                          <option key={index} value={projects_qty}>{projects_qty}</option>
                        ))}
                      </select>
                      <label>{projectsError}</label>
                    </div>
                    <div className="column covid-input">
                      <label>Estatus del proyecto</label>
                      <select className="input select"
                      onChange={e => setProjectStatus(e.target.value)}
                      >
                        <option>Selecciona tu etapa</option>
                        {project_stages.map((project_stage,index) =>(
                          <option key={index} value={project_stage}>{project_stage}</option>
                        ))}
                      </select>
                      <label>{projectStatusError}</label>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <button className="button btn-main" onClick={handleSuscribe}>
                        Solicita el apoyo
                      </button>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
}

export default Covid
